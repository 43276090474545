.App {
  text-align: center;
}

.logo {
  height: 50vmin;
}

.App-logo {
  height: auto;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  background: url("underBackg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px);
  color: rgb(204, 24, 24);
}


.App-link {
  color: #000000;
}

@keyframes App-logo-spin {
  /* from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  } */
  from {
    transform: skew(10deg) scale(1.1,1.1) rotate(20deg) translate(10px, 20px);
  }
  to {
transform: rotate(0deg);
  }
  
}
